import { createContext, useContext, useEffect, useState } from "react";
import { FirebaseContext } from "./Firebase";

import { fromEvent, of } from "rxjs";
import { debounceTime, filter, switchMap, tap } from "rxjs/operators";
import { useLocation } from "react-router-dom";
import { DataContext } from "./Data";
import getIframeLinkByNavlink from "../utils/getIframeLinkByNavlink";

import { AstratoConfig } from "./Config";
const ASTRATO_URL = AstratoConfig.baseURL;
const APP_NAME = AstratoConfig.appName;

const AstratoContext = createContext();

const AstratoProvider = ({ children }) => {
  const location = useLocation();

  const { v2Routes, space, account, callConfigAstratoConnection } = useContext(DataContext);
  const { functions } = useContext(FirebaseContext);
  const [ticket, setTicket] = useState();
  const [iframeSrc, setIframeSrc] = useState();
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  const refreshUser = async () => {
    const { data } = await functions.refreshUserSession();
    setTicket(data.ticket);
    return data.ticket;
  };

  useEffect(() => {
    callConfigAstratoConnection(APP_NAME[space], account, space);
  }, []);

  useEffect(() => {
    const currentRoute = v2Routes[`dtc_routes`]?.find((r) => r.links.some((l) => location.pathname.includes(l.navlink)));

    if (currentRoute) {
      const currentLink = currentRoute.links.find((l) => location.pathname.includes(l.navlink));
      setIframeSrc(currentLink?.iframeLink || null);
    } else if (location.pathname.includes("v2/dtc")) {
      if (v2Routes.hasOwnProperty("dtc_routes")) {
        const result = getIframeLinkByNavlink(v2Routes[`dtc_routes`], "kpi_dashboard");
        setIframeSrc(result?.iframeLink || null);
      }
    }
  }, [location, v2Routes]);

  useEffect(() => {
    console.log("Open listening...");
    const subscription = fromEvent(window, "message")
      .pipe(
        debounceTime(400),
        filter((e) => {
          if (e.data?.type === "Astrato" && e.data?.message === "401Error") {
            console.log("NEW MESSAGE FROM ASTRATO", e);
            setIsAuthenticated(false);
          }
          return e.data?.type === "Astrato" && e.data?.message === "401Error";
        }),
        switchMap(() =>
          refreshUser().then((res) => {
            console.log("TOKEN GENERATED", res);
            return res;
          })
        ),
        filter((data) => data !== null),
        switchMap((data) => {
          console.log("TOKEN IN FETCH", `${ASTRATO_URL}auth/proxy/oem/ticket/${data}?embed`);
          return fetch(`${ASTRATO_URL}auth/proxy/oem/ticket/${data}?embed`, {
            credentials: "include",
          })
            .then((response) => {
              console.log("FETCH RESPONSE", response);
              return response.json();
            })
            .catch((error) => {
              console.error("Error during ticket fetch:", error);
              return of(null);
            });
        }),
        tap(() => {
          setIframeSrc((prev) => `${prev.split("?")[0]}`);
          console.log("Authenticated");
          setIsAuthenticated(true);
        })
      )
      .subscribe();
    return () => subscription.unsubscribe();
  }, []);

  return <AstratoContext.Provider value={{ ticket, refreshUser, iframeSrc, isAuthenticated }}>{children}</AstratoContext.Provider>;
};

export { AstratoProvider, AstratoContext };
