import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { AuthContext } from "./Authorization";
import { FirebaseContext } from "./Firebase";
import { LoggingContext } from "./Logger";

import { QlikConfig } from "./Config";

import enoLogo from "../assets/Enolytics_logo-1.jpg";

const DataContext = React.createContext();
const Version = { EnoVersion: "1.2.3" };

function DataProvider({ children }) {
  const navigate = useNavigate();
  const { log } = React.useContext(LoggingContext);
  const location = useLocation();
  log.debug("Data Provider Rendered...", location.pathname);

  const { authData } = React.useContext(AuthContext);
  const { db, storage, functions } = React.useContext(FirebaseContext);

  // Config
  const [dynamicRoutes, setDynamicRoutes] = React.useState();
  const [qlikConfig, setQlikConfig] = React.useState();
  const [version, setVersion] = React.useState(Version.EnoVersion);

  // Session State
  const [account, setAccount] = React.useState(); // Current Session Account
  const [space, setSpace] = React.useState(); // Current Session Space
  const [error, setError] = React.useState(); // Current Error State
  const [accountLogo, setAccountLogo] = React.useState(enoLogo);

  // V2
  const [v2Routes, setV2Routes] = useState({});
  const [v2RouteSelected, setV2RouteSelected] = useState({});
  const [loadingChangeSpace, setLoadingChangeSpace] = useState(false);

  // ~ Debug
  if (log.getLevel() < 2) {
    window.enolog.Data = {
      account: account,
      space: space,
      error: error,
      accountLogo: accountLogo,
      version: version,
      dynamicRoutes: dynamicRoutes,
      qlikConfig: qlikConfig,
    };
  }
  // ~ Debug

  //^ On Load -- Update Qlik Config
  React.useEffect(() => {
    setQlikConfig(QlikConfig);
    setVersion(Version.EnoVersion);
  }, []);

  //^ On Load -- Update Dynamic Routes
  React.useEffect(() => {
    const unsubscribe = db
      .collection("portal_config")
      .doc("routes")
      .onSnapshot((doc) => {
        //Snapshot forces state update when data changes
        if (doc.exists) {
          // we have data
          let routes = doc.data();
          setDynamicRoutes(routes);
        } else {
          // no data
          log.debug("No Dynamic Route Data Returned");
        }
      });
    return () => {
      unsubscribe();
    };
  }, [db]);

  React.useEffect(() => {
    const unsubscribe = db
      .collection("portal_config")
      .where("version", "==", "V2")
      .onSnapshot((querySnapshot) => {
        var data = {};
        querySnapshot.forEach((doc) => {
          data[doc.id] = doc.data().data;
        });
        setV2Routes(data);
      });

    return () => {
      unsubscribe();
    };
  }, [db]);

  //^ On Navigation or authData Change - set account/route/space
  React.useEffect(() => {
    // Get account/route from URL
    let [blank, navRoute, navRoute2] = location.pathname.split("/");

    //Get Account from default account if not in local storage

    let navAccount = account || localStorage.getItem(`${document.location.hostname}-EID`);
    if (!navAccount) {
      navAccount = authData?.user?.protected?.default_account || Object.keys(authData?.accounts)[0];
    }

    // If No Route in URL then get from user default or default to dtc
    if (!navRoute) {
      navRoute = authData?.user?.protected?.default_route || "dtc"; // default to dtc space if no default_route
    }

    if (navRoute) {
      const newRoute = navRoute === "v2" ? `${navRoute}/${navRoute2}` : navRoute;
      setSpace(newRoute);
      changeAccountSpace(navAccount, newRoute);
    }

    if (!account && navAccount) {
      setAccount(navAccount);
      // setSpace(navRoute);

      // Change Logo
      let storageRef = storage.ref();
      storageRef
        .child(`accounts/${navAccount}/${navAccount}.png`)
        .getDownloadURL()
        .then((downloadURL) => {
          setAccountLogo(downloadURL);
        })
        .catch(() => {
          setAccountLogo(enoLogo);
        });
    }
  }, [location, authData, account]);

  //~ Store account ID to local storage
  React.useEffect(() => {
    if (account && account != undefined) {
      localStorage.setItem(`${document.location.hostname}-EID`, account);
    }
  }, [account]);

  // Function to change the account space
  const changeAccountSpace = async (acctNum, spaceId) => {
    try {
      log.debug("Changing AccountSpace:", acctNum, spaceId);
      setAccount(acctNum);
      setSpace(spaceId);

      //Change Logo
      let storageRef = storage.ref();
      storageRef
        .child(`accounts/${acctNum}/${acctNum}.png`)
        .getDownloadURL()
        .then((downloadURL) => {
          setAccountLogo(downloadURL);
        })
        .catch(() => {
          setAccountLogo(enoLogo);
        });
    } catch (error) {}
  };

  const callConfigAstratoConnection = async (space, account, path = "v2/dtc") => {
    try {
      setLoadingChangeSpace(true);
      const { data } = await functions.configAstratoConnection({ space, account });
      setLoadingChangeSpace(false);
      return data;
    } catch (error) {
      setLoadingChangeSpace(false);
      log.debug("ERROR CALL FC CONFIG USER CONNECTION", error);
      log.debug("SPACE ERROR", path);
      navigate(`${path}/error`);
    }
  };

  // Function to get document data
  const getMapping = async (acc = account) => {
    const ref = await db.collection("mapping").where("account", "==", acc.toString()).get();
    if (ref.empty) {
      return undefined;
    }

    let data = [];
    ref.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return data;
  };

  const getMappingTemplate = async () => {
    const ref = await db.collection("mapping_template").get();

    if (ref.empty) {
      return undefined;
    }

    let data = {};
    ref.forEach((doc) => {
      data[doc.id] = doc.data();
    });

    return data;
  };

  const getDoc = async (enumData) => {
    const doc = await db.collection(enumData.collection).doc(enumData.document).get();
    if (doc.empty) {
      return [];
    }

    return doc.data();
  };

  const getMappingConfigAccount = async () => {
    const ref = await db.collection("mapping").where("account", "==", account.toString()).where("mapping_type", "==", "config").get();
    if (ref.empty) {
      return undefined;
    }

    let data = [];
    ref.forEach((doc) => {
      data.push({
        id: doc.id,
        ...doc.data(),
      });
    });

    return data[0];
  };

  const getMappingSettings = async () => {
    const doc = await db.collection("portal_config").doc("mapping_settings").get();
    if (doc.empty) {
      return [];
    }

    let { data } = doc.data();

    const filteredData = data.filter((i) => i.enabled);

    return filteredData;
  };

  const saveMappingConfigAccount = async (account_eid, obj) => {
    const docRef = await db.collection("mapping").where("account", "==", account_eid).where("mapping_type", "==", "config").get();

    if (docRef.empty) {
      const newObj = {
        account: account_eid,
        mapping_type: "config",
        data: obj,
      };
      await db.collection("mapping").add(newObj);
      return;
    }

    docRef.forEach((doc) => {
      doc.ref.update({ data: obj });
    });
  };

  return (
    <DataContext.Provider
      value={{
        version,
        account,
        space,
        changeAccountSpace,
        accountLogo,
        error,
        setError,
        qlikConfig,
        dynamicRoutes,
        getMapping,
        getMappingTemplate,
        getDoc,
        getMappingConfigAccount,
        getMappingSettings,
        saveMappingConfigAccount,
        v2Routes,
        v2RouteSelected,
        setV2RouteSelected,
        callConfigAstratoConnection,
        loadingChangeSpace,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}

export { DataProvider, DataContext };
