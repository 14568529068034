import React, { useContext, useState } from "react";
import { TbSpaces } from "react-icons/tb";
import { BsArrowReturnLeft } from "react-icons/bs";

import { AuthContext } from "../../context/Authorization";
import { DataContext } from "../../context/Data";
import { useNavigate } from "react-router-dom";

const SpacesMenu = ({ isOpen, toggleMenu }) => {
  const navigate = useNavigate();
  const { useAuth, authData } = useContext(AuthContext);
  const { account, changeAccountSpace, space, callConfigAstratoConnection } = useContext(DataContext);
  const { userHasPermission, accountHasPermission } = useAuth();

  const [searchTerm, setSearchTerm] = useState("");
  const [spaces, setSpaces] = React.useState([]);

  const filteredSpaces = spaces.filter((space) => `${space?.label} - ${space.data.account} - ${space.data.route}`.toLowerCase().includes(searchTerm.toLowerCase()));

  React.useEffect(() => {
    let authSpaces = Object.entries(authData?.accounts).reduce((acc, [k, v]) => {
      if (userHasPermission(["ROUTE_DTC_USER"], k) && accountHasPermission("ROUTE_DTC_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DTC",
          version: "v1",
          prepend: v?.account_name || "",
          append: "DTC",
          color: "bg-[#00599d]",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "dtc",
          },
          checked: k === account && space === "dtc" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DEPL_USER"], k) && accountHasPermission("ROUTE_DEPL_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DEPL",
          version: "v1",
          prepend: v?.account_name || "",
          append: "DEPLETION",
          color: "bg-[#00599d]",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "depl",
          },
          checked: k === account && space === "depl" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DTC_V2_USER"], k) && accountHasPermission("ROUTE_DTC_V2_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DTC",
          version: "v2",
          prepend: v?.account_name || "",
          append: "DTC V2",
          color: "bg-[#EFB700]",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "v2/dtc",
          },
          checked: k === account && space === "v2/dtc" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_DEPL_V2_USER"], k) && accountHasPermission("ROUTE_DEPL_V2_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "DEPL",
          version: "v2",
          prepend: v?.account_name || "",
          append: "DEPLETION V2",
          color: "bg-[#EFB700]",
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "v2/depl",
          },
          checked: k === account && space === "v2/depl" ? "on" : undefined,
        });
      }

      if (userHasPermission(["ROUTE_HAAS_VIEW"], k) && accountHasPermission("ROUTE_HAAS_VIEW", v)) {
        acc.push({
          label: v?.account_name,
          app: "HAAS",
          version: "v1",
          prepend: v?.account_name || "",
          append: "HAASBROS",
          color: "bg-[#00b4d8]",
          searchableLabel: `${v?.account_name} ${k}`,
          data: {
            secondaryContent: `EID: ${k}`,
            account: k,
            route: "haasbros",
          },
          checked: k === account && space === "haasbros" ? "on" : undefined,
        });
      }

      return acc;
    }, []);

    authSpaces.sort((x, y) => {
      return x.data.account === account ? -1 : y.data.account === account ? 1 : 0;
    });

    setSpaces(authSpaces);
  }, [account, authData, space]);

  const onChange = async (options) => {
    const {
      app,
      version,
      data: { account, route },
    } = options;

    if (version === "v2") {
      callConfigAstratoConnection(app, account);
    }

    setSearchTerm("");
    toggleMenu();
    changeAccountSpace(account, route);
    navigate(route);
  };

  return (
    <div className="relative">
      <button data-testid="spaces-menu" onClick={toggleMenu} className="flex justify-center items-center w-8 h-8 bg-grey-hover rounded-full hover:bg-grey ">
        <TbSpaces className="h-6 w-6" />
      </button>
      {isOpen && (
        <div style={{ zIndex: 9999 }} className="absolute right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-100" data-testid="open-menu-spaces">
          <div className="p-2">
            <input type="text" placeholder="Find a space" className="w-full px-3 py-2 border rounded-md" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
          </div>
          <ul className="py-1 max-h-64 flex-grow overflow-y-auto">
            <div className="border-[0.5px] border-solid border-grey-hover"></div>

            {filteredSpaces &&
              filteredSpaces.map((space, index) => (
                <div key={index}>
                  <button
                    onClick={() => onChange(space)}
                    className={`flex justify-between px-4 py-2  w-full text-base  text-subsection-title-color hover:bg-grey-hover ${space.checked && " bg-grey-selected font-bold"}`}
                  >
                    <div>{space.label}</div>

                    <div className="flex  items-center">
                      <span className={`${space.color}  text-white text-sm font-medium me-2 px-2.5 py-0.5 rounded `}>{space.append}</span>
                      {space.checked && <BsArrowReturnLeft className="" />}
                    </div>
                  </button>
                  <div className="border-[0.5px] border-solid border-grey-hover"></div>
                </div>
              ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default SpacesMenu;
