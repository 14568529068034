import React, { useContext } from "react";
import SideNavV2 from "../../components/SideNav/SideNavV2";
import { AuthContext } from "../../context/Authorization";
import { DataContext } from "../../context/Data";
import NoRouteV2 from "../../components/StatusPages/NoRouteV2";

export default function Depletion_V2() {
  const { useAuth } = useContext(AuthContext);
  const { userHasPermission } = useAuth();

  const { account } = useContext(DataContext);

  if (!userHasPermission(["ROUTE_DEPL_V2_USER"], account)) {
    return <NoRouteV2 />;
  }

  return (
    <div className="flex flex-grow overflow-hidden h-[calc(100vh-99px)]">
      <SideNavV2 route="depl" />
      <main className="flex-grow p-8 overflow-y-auto">Depletion V2</main>
    </div>
  );
}
